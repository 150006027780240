import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

function Checkbox({
  onChange, children, checked = false, type = "checkbox", disabled = false,
}) {
  return (
    <StyledBox type={type} onClick={onChange} disabled={disabled}>
      <div className="container">
        <input type={type} checked={checked} />
        <span className="checkbox" />
      </div>
      <Box
        className="checkbox-label"
        sx={{ marginLeft: (theme) => `${theme.spacing(1)} !important` }}
      >
        {children}
      </Box>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  .checkbox-label {
    cursor: ${({ disabled }) => !disabled && "pointer"};
  }
  .container > input {
    min-height: 20px;
    min-width: 20px;
    cursor: pointer;
  }
  ${({ type, disabled, theme }) => type === "checkbox"
    && `
      .container {
        display: block;
        width: 20px;
        > input {
          display: none;
        }
        .checkbox {
          height: 20px;
          width: 20px;
          display: block;
          border-radius: 2px;
          cursor: ${!disabled && "pointer"};
          transition: 0.375s;
          border: 2px solid ${theme.palette.neutral.main};
        }
        > input:checked ~ .checkbox {
          rotate: 45deg;
          translate: 10px -5px;
          width: 10px;
          border-color: ${theme.palette.secondary.main};
          border-top-color: transparent;
          border-left-color: transparent;
          border-radius: 0;
          margin-right: 1rem;
        }
      }
    `};
`;

export default Checkbox;
